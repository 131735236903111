module.exports = {
  // App Info
  appstore_link         : "https://apps.apple.com/app/apple-store/id1614662488?pt=123686483&ct=main-web&mt=8",                                  // Enter App Store URL.
  playstore_link        : null,                                  // Enter Google Play Store URL.
  google_analytics_ID   : "a",                                        // Enter Google Analytics ID or ""
  presskit_download_link: "",                                  // Enter a link to downloadable file or (e.g. public Dropbox link to a .zip file). 
  video_or_screenshot   : "screenshot",                                           // "screenshot" or "video"
  app_url               : "https://rpgguideme.app",                            // Domain of your website without path_prefix.
  path_prefix           : "/",                                                    // Prefixes all links. For cases when deployed to example.github.io/automatic-gatsbyjs-app-landing-page/.
  app_name              : "Guiding Ring - Elden Ring Companion",
  app_price             : "Free",
  app_description       : "The best ER companion tool for gamers to track their progress and explore items in Elden Ring!",
  app_keywords          : ["er companion", "mapgenie", "mapgenie elden ring", "mapgenie elden ring app", "Companion app", "ios companion app", "Elden Ring Map", "ER Map", "Elden Ring Tears", "Elden Ring Collectibles", "Elden Ring Map Fragments", "Elden Ring Map", "Elden Ring Quests", "Elden Ring NPC", "Elden Ring Guide", "Elden Ring Checklist", "Elden Ring Bosses", "Elden Ring Enemies", "Elden Ring Gold Seed Locations", "Elden Ring Loot", "elden ring", "elden", "rpg guides", "rpg", "gamers", "games", "guides", "elden ring companion", "dark souls", "elden ring guides"],

  // Personal Info
  your_name              : "Polina",
  your_link              : null,
  your_city              : "Bay Area",
  email_address          : "contact@rpgguideme.app",
  linkedin_username      : null,
  facebook_username      : null,
  instagram_username     : null,
  twitter_username       : null,
  github_username        : null,
  youtube_username       : null,

  // Features List
  features               : [
    {
      title: "Track Your Progress",
      description:
        "Create separate entries to track the progress during your playthrough",
      fontawesome_icon_name: "book-open",
    },
    {
      title: "ER Boss Guides",
      description:
        "Explore detailed info about game bosses and read different tips & strategies that will help you to defeat the enemies!",
      fontawesome_icon_name: "crown",
    },
    {
      title: "Quests & NPCs & World Locations",
      description:
        "Create and add notes for game quests, bosses, and world locations. Remember the story when you come back to the game after some time",
      fontawesome_icon_name: "arrows-to-circle",
    },
    {
      title: "Walkthrough Guides",
      description:
        "Follow detailed guides that help you to explore all questlines and meet NPCs in the chronological order. Discover all 6 endings in the game by following our guide!",
      fontawesome_icon_name: "book-reader",
    },
    {
      title: "Essential Tool for Gamers",
      description:
        "Perfect tool to track your progress in games that do not have a journal. Built for gamers who want to enjoy the game story",
      fontawesome_icon_name: "gamepad",
    },
    {
      title: "Elden Ring Compendium",
      description:
        "See details of over 2,300 items, weapons, armors, bosses, NPCs, locations, and more that can be found in the Elden Ring world",
      fontawesome_icon_name: "list",
    },
    {
      title: "Ad free",
      description:
        "Guiding Ring does not waste your attention or battery life with ads.",
      fontawesome_icon_name: "ban",
    },
    {
      title: "Made for iOS",
      description:
        "Guiding Ring was built from ground up for iOS and iPadOS using SwiftUI technology. It has a native interface, and it is fully integrated with all system features.",
      fontawesome_icon_name: "mobile",
    },
    {
      title: "No personal data collected",
      description:
        "We take your privacy seriously: Guiding Ring does not collect any of your personal data or any data that could identify you.",
      fontawesome_icon_name: "star",
    },
  ],
  header_background             : "#BF4903",
  topbar_title_color            : "#ffffff",
  cover_overlay_color_rgba      : "rgba(54, 59, 61, 0.8)",
  device_color                  : "black",                          // Set to "black", "blue", "coral", "white", or "yellow"
  body_background_color         : "ffffff",
  primary_text_color            : "#000",
  content_width                 : "1170px",
  font                          : `"Helvetica Neue", sans-serif`,
  link_color                    : "#fffff5",
  app_title_color               : "#ffffff",
  app_price_color               : "#ffffff",
  app_description_color         : "#ffffff",
  feature_title_color           : "#000000",
  feature_text_color            : "#666666",
  feature_icons_foreground_color: "#1d63ea",
  feature_icons_background_color: "#e6e6e6",
  social_icons_foreground_color : "#666666",
  social_icons_background_color : "#e6e6e6",
  footer_text_color             : "#666666",
}

// image from https://www.reddit.com/r/Eldenring/comments/ulo3uu/from_liurnia_erdtree_and_the_moon_looks_conjoined/